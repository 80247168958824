import { COUNTRIES } from "@assets/countries";
import { Icons } from "@components/icons";
import PartFramework from "@components/inquiry/components/part-framework";
import PartSingle from "@components/inquiry/components/part-single";
import OrderConditions from "@components/new-order/components/order-conditions";
import { useGetOfferDetailsId } from "@hooks/useGetOfferDetailsId";
import { useGetTaxByCountry } from "@hooks/useGetTaxByCountry";
import { usePlaceOrder } from "@hooks/usePlaceOrder";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { MoneyFactory, MoneyUtils } from "@lib/money";
import { cn } from "@lib/utils";
import { OfferDetailsResponse } from "@models/offer";
import { Contact } from "@models/user";
import { useUserStore } from "@store/user.store";
import { useInquiry } from "@store/wizard-inqury";
import { Button } from "@ui/button";
import { Card, CardContent, CardHeader } from "@ui/card";
import { Separator } from "@ui/separator";
import { Tabs, TabsList, TabsTrigger } from "@ui/tabs";
import { findObjectsBasedOnId } from "@utils/objects";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const Summary = () => {
  useScrollToTop();
  const [invoicingUser, setInvoicingUser] = useState<Contact | null>();
  const [filterTable, setFilterTable] = useState<"all" | "framework" | "single">("all");
  const [orderConfirmationUser, setOrderConfirmationUser] = useState<Contact | null>();
  const [purhasingnUser, setPurhasingnUser] = useState<Contact | null>();
  const params = useParams();
  const { user } = useUserStore();
  const { mutate: placeOrderMutate, isPending } = usePlaceOrder({
    userId: user?.contactId as string,
    userEmail: user?.email as string,
  });
  const { data: offerDetails } = useGetOfferDetailsId(params.offerId);

  const {
    addresses,
    order,
    defaultContacts,
    setCurrentStep,
    singleOfferLines,
    frameworkOfferLines,
  } = useInquiry((state) => state);
  useEffect(
    function setInvoicingAndOrderConfirmationUsers() {
      if (!user) {
        return;
      }
      if (defaultContacts?.contactInvoicing) {
        const contactInvoicingUser = findObjectsBasedOnId(
          user?.contacts,
          defaultContacts.contactInvoicing,
        ) as Contact;
        setInvoicingUser(contactInvoicingUser);
      }
      if (defaultContacts?.contactOrderConfirmation) {
        const contactOrderConfirmationUser = findObjectsBasedOnId(
          user?.contacts,
          defaultContacts.contactOrderConfirmation,
        ) as Contact;
        setOrderConfirmationUser(contactOrderConfirmationUser);
      }
      if (defaultContacts?.contactPurchasing) {
        const contactPurchasingUser = findObjectsBasedOnId(
          user?.contacts,
          defaultContacts.contactPurchasing,
        ) as Contact;
        setPurhasingnUser(contactPurchasingUser);
      }
    },
    [user, defaultContacts],
  );
  const { data: taxResponse } = useGetTaxByCountry({
    country: addresses.shippingAddress.country,
    taxId: addresses.billingAddress.taxNumber,
  });

  const taxPrice = taxResponse?.data
    ? (taxResponse?.data?.taxPercent / 100) * (order.totalPrice || 0)
    : 0;

  const totalPriceMoney = MoneyFactory.create(order.totalPrice);
  const taxPriceMoney = MoneyFactory.create(taxPrice);

  const handlePlaceOrder = () => {
    const singleOfferLineItems = singleOfferLines.map((offer) => {
      const certificateOfferLineIds = offer.certificateLines
        ?.map((certificateLine) => {
          if (!certificateLine.offerLineId) return;
          return { orderLineId: certificateLine.offerLineId };
        })
        .filter(Boolean);
      return (certificateOfferLineIds || []).concat({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        orderLineId: offer.quantities.find(
          (q) => q.offerLineId === offer.selectedOfferLineId,
        )?.offerLineId,
      });
    });
    const frameworkAgreementLineIds = frameworkOfferLines
      ?.map((offer) => {
        const certificateOfferLineIds = offer.certificateLines?.map(
          (certificateLine) => ({
            frameworkAgreementLineId: certificateLine.offerLineId,
            batchQuantity: certificateLine.quantity,
            musterDeliveryDate: null,
            sampleQuantity: null,
            firstBatchDeliveryDate: null,
            nextBatchesDeliveryDate: null,
          }),
        );
        return [
          ...(certificateOfferLineIds || []),
          {
            frameworkAgreementLineId: offer.quantities.find(
              (q) => q.offerLineId === offer.selectedOfferLineId,
            )?.offerLineId,
            musterDeliveryDate: offer.quantities.find(
              (q) => q.offerLineId === offer.selectedOfferLineId,
            )?.deliveryTime,
            sampleQuantity: offer.quantities.find(
              (q) => q.offerLineId === offer.selectedOfferLineId,
            )?.quantitySample,
            batchQuantity: offer.quantities.find(
              (q) => q.offerLineId === offer.selectedOfferLineId,
            )?.quantityPerBatches,
            firstBatchDeliveryDate: offer.quantities.find(
              (q) => q.offerLineId === offer.selectedOfferLineId,
            )?.deliveryTimeFirstBatch,
            nextBatchesDeliveryDate: offer.quantities.find(
              (q) => q.offerLineId === offer.selectedOfferLineId,
            )?.deliveryTimePerBatch,
          },
        ];
      })
      .flat();
    placeOrderMutate({
      payload: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        order:
          singleOfferLineItems.length > 0
            ? {
                ...order,
                deliveryDate: order.deliveryDate || undefined,
                orderLines: singleOfferLineItems?.flat(),
              }
            : null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        frameworkAgreement:
          frameworkAgreementLineIds.length > 0
            ? {
                ...order,
                comments: order.comments || "",
                deliveryDate: undefined,
                files: order.files || [],
                frameworkAgreementLines: frameworkAgreementLineIds,
                reference: order.reference || "",
                totalPrice: order.totalPrice || 0,
              }
            : null,
        defaultContacts,
        addresses,
      },
    });
  };
  return (
    <div>
      <div className="space-y-6 pb-40">
        <div className="space-y-3">
          <div className="text-secondary-foreground text-sm">
            {offerDetails?.data?.offerNr}
          </div>
          <h1 className="text-3xl font-bold">{t("summary_intro-heading")}</h1>
        </div>
        <div className="space-y-8">
          <Card className="col-span-1 h-max text-sm shadow-2xl">
            <CardHeader>
              <h2 className="text-2xl font-bold">{t("summary_your-order-details")}</h2>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex flex-col md:flex-row">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5 h-full">
                  <div className="space-y-2">
                    <div className="font-bold font-display">
                      {t("wizard-ord-details_internal-ord-num")}
                    </div>
                    <div>{order.reference}</div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold font-display">
                      {t("wizard-ord-details_shipping-address")}
                    </div>
                    <div>
                      <div>{addresses.shippingAddress.companyName}</div>
                      <div>{addresses.shippingAddress.street}</div>
                      <div>
                        {addresses.shippingAddress.postalCode} {""}
                        {addresses.shippingAddress.city},{" "}
                        {COUNTRIES[addresses.shippingAddress.country]}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold font-display">
                      {t("wizard-ord-details_billing-address")}
                    </div>
                    <div>
                      <div>{addresses.billingAddress.companyName}</div>
                      <div>{addresses.billingAddress.street}</div>
                      <div>
                        {addresses.billingAddress.postalCode} {""}
                        {addresses.billingAddress.city},{" "}
                        {COUNTRIES[addresses.billingAddress.country]}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold font-display">
                      {t("contacts_purchasing")}
                    </div>
                    <div className="truncate">
                      {purhasingnUser ? (
                        <>
                          <div>
                            {purhasingnUser?.firstName} {purhasingnUser?.lastName}
                          </div>
                          <div className="text-xs">{purhasingnUser?.email}</div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold">{t("contacts_invoicing")}</div>
                    <div className="truncate">
                      {invoicingUser ? (
                        <>
                          <div>
                            {invoicingUser?.firstName} {invoicingUser?.lastName}
                          </div>
                          <div className="text-xs">{invoicingUser?.email}</div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold">{t("contacts_order-confirm")}</div>
                    <div className="">
                      {orderConfirmationUser ? (
                        <>
                          <div>
                            {orderConfirmationUser?.firstName}{" "}
                            {orderConfirmationUser?.lastName}
                          </div>
                          <div className="text-xs">{orderConfirmationUser?.email}</div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>
                <Separator
                  orientation="vertical"
                  className="h-[174px] mx-10 hidden md:block"
                />
                <div className="mt-4 md:mt-0 space-y-2">
                  <div className="space-y-2">
                    <div className="font-bold font-display">
                      {t("summary_order-document")}
                    </div>
                    <div className="space-y-2">
                      {order.files.length >= 1
                        ? order.files.map(({ fileId, fileName, file }) => (
                            <a
                              href={file}
                              download={fileName}
                              target="_blank"
                              key={fileId}
                              className="flex justify-between items-center"
                              rel="noreferrer"
                            >
                              <div className="flex space-x-2 font-bold text-primary">
                                <Icons.page className="w-4 h-4" />
                                <span className="text-sm">{fileName}</span>
                              </div>
                            </a>
                          ))
                        : "-"}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold font-display">
                      {t("wizard-ord-details_comment")}
                    </div>
                    <div>{order.comments || "-"}</div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* Here we can add the summary table */}
          <Card className="border-none shadow-2xl">
            <CardHeader className="flex flex-row items-center justify-between pb-0">
              <h1 className="text-2xl font-bold font-display">
                {t("offers-table-columns_components")}
              </h1>
              <div>
                <Tabs
                  value={filterTable}
                  onValueChange={(val) =>
                    setFilterTable(val as "all" | "framework" | "single")
                  }
                  defaultValue="all"
                  className={cn(
                    "space-y-4",
                    (singleOfferLines.length < 1 || frameworkOfferLines.length < 1) &&
                      "hidden",
                  )}
                >
                  <TabsList className="backdrop-blur-md bg-white/30">
                    {[
                      { text: "status-all", value: "all" },
                      {
                        text: "order-details-sidebar_framework-agreement",
                        value: "framework",
                      },
                      {
                        text: "dashboard-singleorder",
                        value: "single",
                      },
                    ].map(({ text, value }) => (
                      <TabsTrigger key={value} value={value}>
                        {t(text)}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </Tabs>
              </div>
            </CardHeader>
            <CardContent className="p-6 space-y-10">
              {frameworkOfferLines && frameworkOfferLines.length >= 1 ? (
                <div className="space-y-4">
                  {filterTable === "all" ||
                  (filterTable === "framework" &&
                    offerDetails?.data?.typeOfOrder === "FrameworkOrder") ? (
                    <h3 className="text-lg font-bold font-display">
                      {t("global_as-frame-agree")}
                    </h3>
                  ) : null}
                  {filterTable === "all" || filterTable === "framework"
                    ? frameworkOfferLines.map((offer, i) => (
                        <PartFramework disable index={i + 1} part={offer} key={i} />
                      ))
                    : null}
                </div>
              ) : offerDetails?.data?.typeOfOrder === "FrameworkOrder" &&
                singleOfferLines.length < 0 ? (
                <div className="flex justify-center">
                  <div className="flex flex-col items-center gap-4">
                    <Icons.inquryIllustration />
                    <h2 className="text-2xl font-bold font-display">
                      {t("1-offersoverview-wearecheckingyourinquirydata")}
                    </h2>
                    <p className="text-muted-foreground">
                      {t("1-offersoverview-thankyouforsubmittingyourinquiry")}
                    </p>
                  </div>
                </div>
              ) : null}
              {singleOfferLines && singleOfferLines.length >= 1 ? (
                <div className="space-y-4">
                  {filterTable === "all" ||
                  (filterTable === "single" &&
                    offerDetails?.data?.typeOfOrder === "FrameworkOrder") ? (
                    <h3 className="text-lg font-bold font-display">
                      {t("global_as-single-order")}
                    </h3>
                  ) : null}
                  {/* New version end */}
                  {filterTable === "all" || filterTable === "single"
                    ? singleOfferLines.map((offer, i) => (
                        <PartSingle disable key={i} part={offer} index={i + 1} />
                      ))
                    : null}
                </div>
              ) : offerDetails?.data?.typeOfOrder === "SingleOrder" ? (
                <div className="flex justify-center">
                  <div className="flex flex-col items-center gap-4">
                    <Icons.inquryIllustration />
                    <h2 className="text-2xl font-bold font-display">
                      {t("1-offersoverview-wearecheckingyourinquirydata")}
                    </h2>
                    <p className="text-muted-foreground">
                      {t("1-offersoverview-thankyouforsubmittingyourinquiry")}
                    </p>
                  </div>
                </div>
              ) : null}
            </CardContent>
          </Card>
          {/* end  */}
          <div className="xl:flex-row flex-col-reverse flex mt-4 ">
            <div className="text-muted-foreground max-w-4xl">
              <Card className="bg-muted md:mt-0 mt-8">
                <CardContent className="p-6 flex gap-2">
                  <div className="mt-2 mr-4">
                    <Icons.excliMark className="fill-muted-foreground" />
                  </div>
                  {offerDetails?.data && (
                    <OrderConditions
                      offerDetails={offerDetails?.data as unknown as OfferDetailsResponse}
                    />
                  )}
                </CardContent>
              </Card>
            </div>
            <div className="ml-auto flex flex-col w-54 justify-end space-y-2 self-start items-baseline xl:mb-0 mb-4">
              <div className="flex items-center space-x-2 justify-between w-full">
                <p className="text-muted-foreground text-sm">{t("summary-table_sum")}</p>
                <p className="font-bold font-display">
                  {MoneyUtils.format(totalPriceMoney)}
                </p>
              </div>
              <div className="flex items-center space-x-2 justify-between w-full">
                <p className="text-muted-foreground text-sm">
                  {t("summary-table_tax")} {taxResponse?.data?.taxPercent || 0}%
                </p>
                <p className="font-bold font-display">
                  {" "}
                  {MoneyUtils.format(taxPriceMoney)}
                </p>
              </div>
              <Separator className="my-3" />
              <div className="flex items-center space-x-2">
                <p className="text-muted-foreground font-display font-bold text-lg">
                  {t("summary-table_total-brutto")}
                </p>
                <p className="font-bold font-display text-lg">
                  {MoneyUtils.format(MoneyUtils.add(taxPriceMoney, totalPriceMoney))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex fixed gap-4 sm:p-8 p-6 left-0 sm:left-[4rem] md:left-[16rem] right-0 bottom-0 border-t bg-background md:w-[calc(100%-16rem)] sm:w-[calc(100%-4rem)] w-full z-20 justify-between flex-col sm:flex-row">
        <Button
          onClick={() => setCurrentStep("1")}
          variant={"outline"}
          type="button"
          disabled={isPending}
        >
          {t("global_go-back-btn")}
        </Button>

        <Button onClick={handlePlaceOrder} disabled={isPending}>
          {t("global_order-btn")}
          {isPending && <Icons.spinner className="mx-2 h-4 w-4 animate-spin" />}
        </Button>
      </div>
    </div>
  );
};

export default Summary;
