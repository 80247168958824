import { Icons } from "@components/icons";
import NavigationLink from "@components/sidebar/nav-link-sidebar";
import { sections } from "@components/sidebar/sidebar";
import SidebarBadge from "@components/sidebar/sidebar-badge";
import SidebarItem from "@components/sidebar/sidebar-item";
import { UserNav } from "@components/user-nav";
import { PAGES_ROUTES } from "@config/routes";
import { cn } from "@lib/utils";
import { PortalRoleEnum } from "@models/user";
import { useUserStore } from "@store/user.store";
import { Separator } from "@ui/separator";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Menu = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const user = useUserStore((state) => state.user);
  return (
    <aside
      className={cn(
        "w-16 transition-[width] md:w-64 h-screen bg-backround-bar sticky top-0 hidden sm:block",
        className,
      )}
      {...props}
    >
      <SidebarBadge />
      <div className="flex flex-col justify-between h-[calc(100vh-150px)]">
        <div className="flex flex-col space-y-10 mx-3 my-6">
          <div className="flex flex-col space-y-2">
            {sections.map(({ title, path, icon }) => (
              <NavigationLink to={path} key={title}>
                {({ active }) => {
                  return <SidebarItem icon={icon} header={title} active={active} />;
                }}
              </NavigationLink>
            ))}
          </div>
        </div>
        <div className="w-full p-4 pb-6 md:w-64">
          <div className="mb-4">
            <div className="flex flex-col mb-3 group">
              <Link
                to="https://www.cnc24.com/contact/"
                target="_blank"
                className="flex items-center justify-center md:justify-start hover:text-brand gap-3"
              >
                <Icons.questionMarkFat className="group-hover:fill-brand size-5 fill-muted-foreground" />
                <h1 className="font-bold group-hover:text-brand hover:no-underline ml-1 sidebar-step-title hidden md:block capitalize text-white">
                  {t("secondary-navigation_help")}
                </h1>
              </Link>
            </div>
            {user?.portalRole === PortalRoleEnum.Admin ||
            user?.portalRole === PortalRoleEnum.Owner ? (
              <div className="group">
                <Link
                  to={PAGES_ROUTES.PROFILE}
                  className="flex items-center justify-center md:justify-start gap-3"
                >
                  <div className="relative">
                    <Icons.userIcon className="text-muted-foreground group-hover:text-brand size-6 -ml-[3px]" />
                    <Icons.add className="absolute size-3 -top-1 -right-1 text-muted-foreground group-hover:text-brand" />
                  </div>
                  <h1 className="font-bold group-hover:text-brand hover:no-underline sidebar-step-title hidden md:block capitalize text-white">
                    {t("dashboard-invite_team_members")}
                  </h1>
                </Link>
              </div>
            ) : null}
            <Separator className="my-4 bg-background" />
            <UserNav />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Menu;
