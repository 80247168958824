import { AccountDetails } from "@access/company.access";

export interface Address {
  vat?: string | null;
  street?: string | null;
  postalCode?: string | null;
  phone?: string | null;
  countryCode?: string | null;
  city?: string | null;
}

/**
 * Represents a User.
 */
export interface User {
  /** The unique ID of this object. */
  uuid: string;
  supportEmail: string;
  accountQualificationNeeded: boolean;

  shippingInfo?: string;
  portalRole: PortalRole;
  lang?: Language;

  email?: string;
  email_verified: boolean;

  id_token: string | null;
  customerInfo: CustomerInfo;
  company: string;
  contactInvoicing: string | null;
  contactMain: string | null;
  contactOpportunity: string | null;
  contactQA: string | null;
  contactConstruction: string | null;
  contactOrderConfirmationEmail: string | null;
  contactInvoicingEmail: string | null;
  contactOrderConfirmation: string | null;
  contactPurchasing: string | null;
  contactPurchasingEmail: string | null;
  contacts: Contact[];
  accountId: string;
  contactId: string;
  contactUUID: string;
  profile: {
    companyDetails: AccountDetails;
    shippingAddress: Address;
    address: Address;
    sameShippingAddressAsCompanyAddress: boolean;
    requirements: {
      documentsRequired: string[];
      certificationsRequired: string[];
      additionalCertifications: string[];
    };
  };
}

export enum JobRoleEnum {
  "QA/QM" = "QA/QM",
  OrderConfirmation = "OrderConfirmation",
  Complaints = "Complaints",
  Invoicing = "Invoicing",
  Logistics = "Logistics",
  ConstructionDepartment = "ConstructionDepartment",
  Purchasing = "Purchasing",
  Production = "Production",
  Inquiry = "Inquiry",
  Opportunity = "Opportunity",
}

export enum DepartmentEnum {
  "Managing Director" = "Managing Director",
  "Production Management" = "Production Management",
  "Production Planning" = "Production Planning",
  "Purchasing Management" = "Purchasing Management",
  Purchasing = "Purchasing",
  Construction = "Construction",
  Assistance = "Assistance",
  Other = "Other",
}

export enum BusinessTypeEnum {
  "Startup" = "Startup",
  "Small Business (< 50 Employees)" = "Small Business (< 50 Employees)",
  "Midsize Business (< 250 Employees)" = "Midsize Business (< 250 Employees)",
  "Enterprise Business (> 250 Employees)" = "Enterprise Business (> 250 Employees)",
  "University or research institution" = "University or research institution",
  "Contract manufacturer" = "Contract manufacturer",
}

export type Language = "English" | "Deutsch";

export type JobRole =
  | "Production"
  | "Inquiry"
  | "Complaints"
  | "Construction Department"
  | "Order Confirmation"
  | "Logistics"
  | "QA / QM"
  | "Invoicing";

export type PortalStatus = "Active" | "Invited" | "Notinvited";

export type PortalRole = "Member" | "Admin" | "Owner";

export enum PortalRoleEnum {
  Member = "Member",
  Admin = "Admin",
  Owner = "Owner",
}

export type ContactPortalStatus =
  | "ContactInSF"
  | "ContactExistsNewEmailSent"
  | "ContactAlReadyRegistered"
  | "NewContact";

export enum ContactPortalStatusEnum {
  ContactInSF = "ContactInSF",
  ContactExistsNewEmailSent = "ContactExistsNewEmailSent",
  ContactAlReadyRegistered = "ContactAlReadyRegistered",
  NewContact = "NewContact",
}

export interface Contact {
  role: JobRole | null;
  portalStatus: PortalStatus;
  portalRole?: PortalRole | null;
  name: string;
  lastName: string;
  id: string;
  firstName: string | null;
  email: string;
  position: string | null;
  department: keyof typeof DepartmentEnum;
  phone: string | null;
  mobile?: string | null;
  contactId: string;
  salutationValue: "Herr" | "Frau";
}

export interface CustomerInfo {
  salutation?: string;
  position?: string | null;
  phone?: string;
  mobile?: string;
  lastName: string;
  firstName: string;
  salutationValue?: "Herr" | "Frau";
  department?: keyof typeof DepartmentEnum;
}

export interface HomeInsightResponse {
  inquiries: number;
  openOrders: number;
  completedOrders: number;
  totalOrders: number;
  totalSpent: number;
  totalPartsOrdered: number;
  avgPricePerPart: number;
  avgQuantityPerOrder: number;
  otd: number;
}
