import "@src/styles/global.css";
import "@config/i18n.ts";

import Sheet from "@components/slide-sheet";
import { queryClient } from "@config/query.config";
import Router from "@config/router";
import { ThemeProvider } from "@hooks/useTheme";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { t } from "i18next";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";
import { clarity } from "react-microsoft-clarity";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
const BUILD_TYPE = import.meta.env.VITE_BUILD_TYPE;
1;
if (BUILD_TYPE === "production") {
  Sentry.init({
    dsn: "https://4bd9dad54f1e192a834033206837eb18@o4505952974405632.ingest.sentry.io/4505952981876736",
    tracePropagationTargets: [import.meta.env.VITE_API_BASE],
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://dashboard.cnc24.com"],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: ["https://dashboard.cnc24.com"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const GA4_PROJECT_ID = import.meta.env.VITE_GA4_PROJECT_ID as string;
// Track only in production since we dont have development TagManager account yet
if (GA4_PROJECT_ID || BUILD_TYPE === "production") {
  TagManager.initialize({ gtmId: GA4_PROJECT_ID });
}
const CLARITY_ID = import.meta.env.VITE_CLARIRY_ID;
if (CLARITY_ID || BUILD_TYPE === "production") {
  clarity.init(CLARITY_ID);
}
/// add error boundary
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <div className="flex h-screen w-screen items-center justify-center bg-background">
          <div className="text-center">
            <h1 className="text-3xl font-bold font-display">{t("error_form-general")}</h1>
            <button
              onClick={() => {
                resetErrorBoundary();
                window.location.reload();
              }}
              className="mt-4 bg-primary text-white px-4 py-2 rounded-md"
            >
              Try again
            </button>
          </div>
        </div>
      )}
    >
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider defaultTheme="light">
            <HelmetProvider>
              <BrowserRouter>
                <Router />
                <Toaster richColors closeButton position="top-right" />
              </BrowserRouter>
            </HelmetProvider>
          </ThemeProvider>
          <ReactQueryDevtools />
          <Sheet />
        </QueryClientProvider>
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
