import { COUNTRIES } from "@assets/countries";
import { Icons } from "@components/icons";
import { EXTRA_DAYS } from "@components/order-new-framework-agreement/steps/order-details.form";
import { SingleDatePicker } from "@components/single-date-picker";
import { PAGES_ROUTES } from "@config/routes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetOfferDetailsId } from "@hooks/useGetOfferDetailsId";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { DAY_MONTH_YEAR_FORMAT, isWithinRange } from "@lib/date";
import { cn } from "@lib/utils";
import { FileObject } from "@models/offer";
import * as CommonService from "@services/common.service";
import { useUserStore } from "@store/user.store";
import { useInquiry } from "@store/wizard-inqury";
import { Button } from "@ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/select";
import { Separator } from "@ui/separator";
import { Textarea } from "@ui/textarea";
import { addDays, format, isBefore, isEqual, parse, subDays } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import React from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as z from "zod";

// eslint-disable-next-line react-hooks/rules-of-hooks
const countiriesTraslation = useObjectTranslations("countries");

const orderDetailsSchema = z.object({
  shippingCompany: z.string().nonempty(t("error_field-required") as string),
  shippingStreet: z
    .string()
    .nonempty(t("dashboard-pleaseaddthedeliverystreetnameandnumber") as string),
  shippingCity: z.string().nonempty(t("dashboard-pleaseaddthedeliverycity") as string),
  shippingPostalCode: z
    .string()
    .nonempty(t("dashboard-pleaseaddthedeliverypostalcode") as string),
  shippingCountry: z.string().nonempty(t("error_field-required") as string),
  shippingAddressAdditionalInfo: z.string().optional(),
  billingCompany: z.string().nonempty(t("error_field-required") as string),
  billingStreet: z.string().nonempty(t("error_field-required") as string),
  billingCity: z.string().nonempty(t("error_field-required") as string),
  billingPostalCode: z.string().nonempty(t("error_field-required") as string),
  billingCountry: z.string().nonempty(t("error_field-required") as string),
  billingAdditionalInfo: z.string().optional(),
  taxNumber: z.string().optional(),
  comments: z.string().optional(),
  contactOrderConfirmation: z.string().optional(),
  contactInvoicing: z.string().optional(),
  contactPurchasing: z.string().optional(),
  deliveryDate: z.string().optional(),
  reference: z.string().nonempty(t("dashboard-werequireyourreferencenumber") as string),
});
type OrderDetailsFormValues = z.infer<typeof orderDetailsSchema>;

const OrderDetailsForm = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const params = useParams();
  const { data: offerDetails } = useGetOfferDetailsId(params.offerId);

  const {
    setOrderDetails,
    order,
    changeDefaultContact,
    defaultContacts,
    changeAddress,
    addresses,
    setCurrentStep,
    setStepStatus,
    filterOutOfferLines,
    setWarningOnSelectedDeliveryDate,
    singleOfferLines,
    frameworkOfferLines,
    warningOnSelectedDeliveryDate,
  } = useInquiry((state) => state);

  const { user } = useUserStore();
  const [uploadingDocs, setUploadingDocs] = useState({ isPending: false, error: "" });

  const productionDaysOnAllLineItems = singleOfferLines?.map(
    (item) =>
      item.quantities.find((q) => item.selectedOfferLineId === q.offerLineId)
        ?.minProductionDays || 0,
  );
  // minimum production days needed
  const minProductionDay =
    productionDaysOnAllLineItems.length > 0
      ? Math.max(...productionDaysOnAllLineItems)
      : EXTRA_DAYS;
  // Calculate min delivery date
  const minDeliveryDate = addDays(new Date(), minProductionDay);
  const handleSearchSelectedDates = (selectedDate: Date) => {
    form.setValue("deliveryDate", format(selectedDate, DAY_MONTH_YEAR_FORMAT));
    setOrderDetails("deliveryDate", format(selectedDate, DAY_MONTH_YEAR_FORMAT));
  };
  const handleOnValueDateChange = (changedDate?: Date) => {
    if (!changedDate) {
      return;
    }

    // Check if selected date is after min delivery
    const selectedDateAfterMinDeliveryDate =
      isBefore(minDeliveryDate, subDays(changedDate, -1)) ||
      isEqual(minDeliveryDate, changedDate);

    // Calculate max delivery date
    const maxDeliveryDate = addDays(minDeliveryDate, 30);

    // Check if selected date is before max delivery
    const selectedDateAfterMaxDeliveryDate =
      isBefore(changedDate, maxDeliveryDate) || isEqual(changedDate, maxDeliveryDate);

    // Check if selected date is higher then max delivery date or lower then min delivery date
    const dateValidation =
      selectedDateAfterMinDeliveryDate && selectedDateAfterMaxDeliveryDate;
    setWarningOnSelectedDeliveryDate(!dateValidation);
    return {
      warning: !dateValidation,
      warningText: "wizard-ord-details_desired-delivery-note_datepicker",
    };
  };
  const isDayHighlighted = (date: Date) => {
    const endDate = subDays(addDays(new Date(), minProductionDay), 1);
    return isWithinRange(date, {
      from: subDays(new Date(), 1),
      to: endDate,
    });
  };

  const defaultValues: Partial<OrderDetailsFormValues> = {
    shippingCompany:
      addresses.shippingAddress.companyName || offerDetails?.data?.shippingCompany || "",
    shippingStreet:
      addresses.shippingAddress.street || offerDetails?.data?.shippingStreet || "",
    shippingPostalCode:
      addresses.shippingAddress.postalCode ||
      offerDetails?.data?.shippingPostalCode ||
      "",
    shippingCity:
      addresses.shippingAddress.city || offerDetails?.data?.shippingCity || "",
    shippingCountry:
      addresses.shippingAddress.country || offerDetails?.data?.shippingCountry || "",
    shippingAddressAdditionalInfo:
      addresses.shippingAddress.additionalInfo ||
      offerDetails?.data?.shippingAdditionalInfo ||
      "",
    billingCompany:
      addresses.billingAddress.companyName || offerDetails?.data?.billingCompany || "",
    billingStreet:
      addresses.billingAddress.street || offerDetails?.data?.billingStreet || "",
    billingPostalCode:
      addresses.billingAddress.postalCode || offerDetails?.data?.billingPostalCode || "",
    billingCity: addresses.billingAddress.city || offerDetails?.data?.billingCity || "",
    billingCountry:
      addresses.billingAddress.country || offerDetails?.data?.billingCountry || "",
    billingAdditionalInfo:
      addresses.billingAddress.additionalInfo ||
      offerDetails?.data?.billingAdditionalInfo ||
      "",
    taxNumber: addresses.billingAddress.taxNumber || offerDetails?.data?.taxNumber || "",
    contactInvoicing: defaultContacts?.contactInvoicing || user?.contactInvoicing || "",
    contactPurchasing:
      defaultContacts?.contactPurchasing || user?.contactPurchasing || "",
    contactOrderConfirmation:
      defaultContacts?.contactOrderConfirmation || user?.contactOrderConfirmation || "",
    deliveryDate:
      order.deliveryDate || minDeliveryDate
        ? format(minDeliveryDate, DAY_MONTH_YEAR_FORMAT)
        : "",
  };
  const form = useForm<OrderDetailsFormValues>({
    resolver: zodResolver(orderDetailsSchema),
    defaultValues,
  });

  async function onSubmitOrderDetails({
    shippingCompany,
    reference,
    comments,
    contactInvoicing,
    contactOrderConfirmation,
    contactPurchasing,
    billingCity,
    billingPostalCode,
    billingCompany,
    billingCountry,
    billingAdditionalInfo,
    billingStreet,
    taxNumber,
    shippingCity,
    shippingStreet,
    shippingCountry,
    shippingPostalCode,
    shippingAddressAdditionalInfo,
    deliveryDate,
  }: z.infer<typeof orderDetailsSchema>) {
    setOrderDetails("reference", reference);
    setOrderDetails("comments", comments || "");
    setOrderDetails("deliveryDate", deliveryDate);
    changeDefaultContact("contactInvoicing", contactInvoicing);
    changeDefaultContact("contactOrderConfirmation", contactOrderConfirmation);
    changeDefaultContact("contactPurchasing", contactPurchasing);
    changeAddress("billingAddress", "city", billingCity);
    changeAddress("billingAddress", "postalCode", billingPostalCode);
    changeAddress("billingAddress", "companyName", billingCompany);
    changeAddress("billingAddress", "country", billingCountry);
    changeAddress("billingAddress", "additionalInfo", billingAdditionalInfo || "");
    changeAddress("billingAddress", "street", billingStreet);
    changeAddress("billingAddress", "taxNumber", taxNumber);
    changeAddress("shippingAddress", "city", shippingCity);
    changeAddress("shippingAddress", "postalCode", shippingPostalCode);
    changeAddress("shippingAddress", "companyName", shippingCompany);
    changeAddress("shippingAddress", "country", shippingCountry);
    changeAddress(
      "shippingAddress",
      "additionalInfo",
      shippingAddressAdditionalInfo || "",
    );
    changeAddress("shippingAddress", "street", shippingStreet);
    filterOutOfferLines();
    setStepStatus("1", true);
    setCurrentStep("2");
  }

  const handleUploadOrderDocuments = async (acceptedFiles: File[]) => {
    setUploadingDocs({ isPending: true, error: "" });

    try {
      const uploadPromises = acceptedFiles.map(async (file) => {
        const payload = {
          recordId: params.offerId as string,
          fileName: file.name,
          file: file,
        };
        const { data: uploadedFile } = await CommonService.uploadUrl(payload);
        return { ...uploadedFile, file: URL.createObjectURL(file) };
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      const currentFiles = (uploadedFiles || []).filter((file) => file !== null) || [];
      setOrderDetails("files", [
        ...useInquiry.getState().order.files,
        ...(currentFiles as FileObject[]),
      ]);

      setUploadingDocs({ isPending: false, error: "" });
    } catch (error) {
      // Handle error
      setUploadingDocs({ isPending: false, error: t("error_form-general") });
    }
  };
  return (
    <div className="pb-40">
      <div className="space-y-2 mb-8">
        <h6 className=" text-sm text-muted-foreground font-bold">
          {t("order-details-sidebar_offer")} {`(${offerDetails?.data?.offerNr})`}
        </h6>
        <h1 className="font-bold md:text-2xl text-xl">
          {t("dashboard-add_or_update_info")}
        </h1>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitOrderDetails)}
          className="grid md:grid-cols-2 gap-4 grid-cols-1"
        >
          <div className="space-y-10">
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("wizard-ord-details_shipping-address")}
              </h3>
              <FormField
                control={form.control}
                name="shippingCompany"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-order-details_company-name")}{" "}
                      <span className="ml-1 text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="shippingAddressAdditionalInfo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("wizard-ord-details_additional-info")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="shippingStreet"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_street")}{" "}
                      <span className="ml-1 text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-3 gap-2">
                <FormField
                  control={form.control}
                  name="shippingPostalCode"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel>
                        {t("wizard-ord-details_plz")}{" "}
                        <span className="ml-1 text-destructive">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="shippingCity"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>{t("wizard-ord-details_city")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="shippingCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel>
                          {t("wizard-ord-details_country")}{" "}
                          <span className="ml-1 text-destructive">*</span>
                        </FormLabel>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {Object.entries(COUNTRIES).map(([code, name]) => {
                            if (code === "AT") {
                              return (
                                <React.Fragment key={code}>
                                  <SelectItem value={code}>
                                    {countiriesTraslation
                                      ? countiriesTraslation[code]
                                      : name}
                                  </SelectItem>
                                  <Separator orientation="horizontal" />
                                </React.Fragment>
                              );
                            }
                            return (
                              <SelectItem key={code} value={code}>
                                {countiriesTraslation ? countiriesTraslation[code] : name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("wizard-ord-details_billing-address")}
              </h3>
              <FormField
                control={form.control}
                name="billingCompany"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-order-details_company-name")}{" "}
                      <span className="ml-1 text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingAdditionalInfo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("wizard-ord-details_additional-info")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billingStreet"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_street")}{" "}
                      <span className="ml-1 text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-3 gap-2">
                <FormField
                  control={form.control}
                  name="billingPostalCode"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel>
                        {t("wizard-ord-details_plz")}{" "}
                        <span className="ml-1 text-destructive">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="billingCity"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>
                        {t("wizard-ord-details_city")}{" "}
                        <span className="ml-1 text-destructive">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="billingCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormLabel>
                          {t("wizard-ord-details_country")}{" "}
                          <span className="ml-1 text-destructive">*</span>
                        </FormLabel>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent className="max-h-96">
                          {Object.entries(COUNTRIES).map(([code, name]) => {
                            if (code === "AT") {
                              return (
                                <React.Fragment key={code}>
                                  <SelectItem value={code}>
                                    {countiriesTraslation
                                      ? countiriesTraslation[code]
                                      : name}
                                  </SelectItem>
                                  <Separator orientation="horizontal" />
                                </React.Fragment>
                              );
                            }
                            return (
                              <SelectItem key={code} value={code}>
                                {countiriesTraslation ? countiriesTraslation[code] : name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="taxNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("dashboard-vat_id")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="space-y-10">
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("wizard-ord-details_your-order-info")}
              </h3>
              <FormField
                defaultValue={order.reference}
                control={form.control}
                name="reference"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("wizard-ord-details_internal-ord-num")}{" "}
                      <span className="ml-1 text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {/* If there are no framework lines, we show the delivery date field. Delivery date is required for the Single Order */}
              {frameworkOfferLines.length === 0 && (
                <FormField
                  control={form.control}
                  name="deliveryDate"
                  render={() => (
                    <FormItem>
                      <FormLabel>
                        {t("wizard-ord-details_desired-delivery-date")}{" "}
                        <span className="text-destructive ml-1">*</span>
                      </FormLabel>
                      <FormControl>
                        <SingleDatePicker
                          dateProps={
                            order.deliveryDate
                              ? parse(
                                  order.deliveryDate,
                                  DAY_MONTH_YEAR_FORMAT,
                                  new Date(),
                                )
                              : minDeliveryDate
                          }
                          onSave={handleSearchSelectedDates}
                          isDayHighlighted={isDayHighlighted}
                          onValueChange={handleOnValueDateChange}
                          disabled={{ before: new Date() }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {warningOnSelectedDeliveryDate && (
                <div className="border rounded-lg p-6 bg-light-yellow flex justify-center items-center gap-4">
                  <div>
                    <Icons.warning className="fill-brand text-white" />
                  </div>
                  <p className="text-sm">{t("wizard-copy_delivery-date-warning")}</p>
                </div>
              )}
              <FormField
                defaultValue={order.reference || ""}
                control={form.control}
                name="comments"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("wizard-ord-details_comment")}</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={cn("col-span-2 justify-items-end")}>
                <div className="mb-2 text-sm">
                  {t("wizard-ord-details_attach-order-doc")}
                </div>
                <Dropzone
                  onDrop={(acceptedFiles) => handleUploadOrderDocuments(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps({ accept: ".pdf" })}
                        disabled={uploadingDocs.isPending}
                      />
                      <div className="rounded-2xl p-8 border border-dashed bg-secondary flex justify-center items-center flex-col text-center space-y-4">
                        <div className="font-bold">{t("dashboard-drag_drop_doc")}</div>
                        <div className="font-sm">{t("dashboard-global_or")}</div>
                        <Button type="button" disabled={uploadingDocs.isPending}>
                          {uploadingDocs.isPending ? (
                            <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                          ) : (
                            <Icons.upload className="w-4 h-4 mr-2" />
                          )}

                          <div className="font-bold">
                            {t("dashboard-uploadnewdocuments")}
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="space-y-3">
                  <AnimatePresence initial={false}>
                    {order.files.map(({ fileId, fileName }) => (
                      <motion.div
                        initial={{ opacity: 0, y: 50, scale: 0.3 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.3 } }}
                        key={fileId}
                        className="flex justify-between items-center mt-2"
                      >
                        <div className="flex space-x-2">
                          <Icons.page className="w-4 h-4" />
                          <span className="text-sm">{fileName}</span>
                        </div>
                        <Button
                          type="button"
                          variant={"ghost"}
                          onClick={() => {
                            setOrderDetails(
                              "files",
                              useInquiry
                                .getState()
                                .order.files.filter((file) => file.fileId !== fileId),
                            );
                          }}
                        >
                          <Icons.trash className="w-4 h-4" />
                        </Button>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <h3 className="text-xl font-bold font-display">
                {t("account-tab_contacts")}
              </h3>
              <div className="grid sm:grid-cols-2 sm:gap-2 gap-0 space-y-2 sm:space-y-0 grid-cols-1">
                <FormField
                  control={form.control}
                  name="contactPurchasing"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("contacts_purchasing")}</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={
                          defaultContacts?.contactPurchasing ||
                          user?.contactPurchasing ||
                          undefined
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="h-[3rem] truncate">
                            <SelectValue
                              placeholder={t("contacts_contacts-dropdown-placeholder")}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="max-h-96">
                          {user?.contacts.map((contact) => {
                            return (
                              <SelectItem value={contact.id} key={contact.id}>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.firstName} {contact.lastName}
                                </div>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.email}
                                </div>
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="contactInvoicing"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("contacts_invoicing")}</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={
                          defaultContacts?.contactInvoicing ||
                          user?.contactInvoicing ||
                          undefined
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="h-[3rem] truncate">
                            <SelectValue
                              placeholder={t("contacts_contacts-dropdown-placeholder")}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="max-h-96">
                          {user?.contacts.map((contact) => {
                            return (
                              <SelectItem value={contact.id} key={contact.id}>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.firstName} {contact.lastName}
                                </div>
                                <div className="text-left sm:text-sm text-xs">
                                  {contact.email}
                                </div>
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="contactOrderConfirmation"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("contacts_order-confirm")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        defaultContacts?.contactOrderConfirmation ||
                        user?.contactOrderConfirmation ||
                        undefined
                      }
                    >
                      <FormControl>
                        <SelectTrigger className="h-[3rem] truncate">
                          <SelectValue
                            placeholder={t("contacts_contacts-dropdown-placeholder")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="max-h-96">
                        {user?.contacts.map((contact) => {
                          return (
                            <SelectItem value={contact.id} key={contact.id}>
                              <div className="text-left sm:text-sm text-xs">
                                {contact.firstName} {contact.lastName}
                              </div>
                              <div className="text-left sm:text-sm text-xs">
                                {contact.email}
                              </div>
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex fixed gap-4 sm:p-8 p-6 left-0 sm:left-[4rem] md:left-[16rem] right-0 bottom-0 border-t bg-background md:w-[calc(100%-16rem)] sm:w-[calc(100%-4rem)] w-full z-20 justify-between flex-col sm:flex-row">
            <Button
              disabled={uploadingDocs.isPending}
              onClick={() =>
                navigate(PAGES_ROUTES.OFFERS_ID_DETAILS(params.offerId as string))
              }
              variant={"outline"}
              type="button"
            >
              {t("global_go-back-btn")}
            </Button>

            <Button disabled={uploadingDocs.isPending}>{t("global_continue-btn")}</Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default OrderDetailsForm;
